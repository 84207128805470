// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@include mat-core();


*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

h1,h2,h3,h4 {
  font-weight: bold !important;
  margin: 0px !important;
}

p, label {
  font-size: 1rem;
  // margin: 0px !important;
}

/* Set core body defaults */
body {
  // min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  width: 100%;
}

button:hover {
  opacity: 0.9;
}

:root {
  font-size: 16px;
  @media only screen and (max-height: 600px) {
    font-size: 14px !important;
  }
  --background-color: #f7f7f7;
  --background-text-color: #2a2a2a;
  --primary-color: #1565c0;
  --primary-text-color: #ffffff;
  --secondary-color: #f69d1d;
  --secondary-text-color: #ffffff;
  --error-color: #8e0000;
  --error-text-color: #ffffff;
  --warning-color: #bb4d00;
  --warning-text-color: #ffffff;
}

html,
body {
  height: 100%;
  min-height: 100vh;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;

}

.form-group {
  margin-bottom: 1rem;
  label {
    color: rgba(0, 0, 0, 0.8);
  }

  input[type=text], input[type=number], input[type=tel], input[type=password], input[type=email], input[type=date] {
    width: 100%;
  }
  input[type=text], input[type=number], input[type=tel], input[type=password], select, input[type=email], input[type=date] {
    height: 3rem;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding-left: 0.5rem;
  }
  input[type=checkbox] {
    height: 25px;
    width: 25px;
    margin-right: 0.5rem;
  }
  .form-input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  select {
    width: 100%;
    height: 3rem;
    font-size: 1rem;
  }

  textarea {
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
}

.text-center {
  text-align: center;
}

mat-spinner {
  margin: 0 auto;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--primary-color);
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background-color: var(#1e1e24);
}

body::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

html,
body {
  background-color: var(--background-color);
}

.package-search,
.package-item {
  @media screen and (max-width: 601px) {
    padding: 1rem 0px !important;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}



.points {
  display: flex;
}

.points-center {
  display: flex;
  justify-content: center;
}
.points-icon {
  margin-right: 0.5rem;
  height: 1.5rem;
}


.mat-bottom-sheet-container {
  padding: 0px !important;
}

p.light {
  font-weight: 100;
}

ox-screen-container .status {
  background-color: var(--secondary-color);
  padding: 0.5rem;
  border-radius: 5px;
}


/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="tel"], input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="tel"], input[type="url"]{ font-size: 16px; }
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.mt-1 {
  margin-top: 1rem;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}
